import { useEffect } from 'react';

import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { DeviceOs, NotificationType } from '@graphql/generated/types';
import { usePushTokenUpsertMutation } from '@src/features-new/core/notifications/usePushNotitfications.generated';
import { ConnectedUserFragment } from '@src/index.generated';
import { isMobileNative } from '@src/utils/isMobileNative';
import { useNavigate } from '@tanstack/react-router';

/* eslint-disable no-console */
export const usePushNotifications = (viewer: ConnectedUserFragment | null) => {
  const [pushTokenUpsert] = usePushTokenUpsertMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isMobileNative()) {
      return;
    }
    PushNotifications.addListener('registration', async (token) => {
      const deviceId = await Device.getId();
      const info = await Device.getInfo();

      const input = {
        pushToken: token.value,
        deviceId: deviceId.identifier || '',
        deviceOS: info.platform === 'android' ? DeviceOs.Android : DeviceOs.Ios,
      };
      console.info(`[notif]: registering device: ${JSON.stringify(input)}`);
      await pushTokenUpsert({
        variables: {
          input: input,
        },
      });
    });

    PushNotifications.addListener('registrationError', (err) => {
      console.error('[notif]: Registration error: ', err.error);
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        console.log('[notif]: Push notification received: ', notification);
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      ({ notification }) => {
        const { data } = notification;

        switch (data.notificationType) {
          case NotificationType.ArticlePublished:
            void navigate({
              to: '/home/articles/$articleId',
              params: { articleId: data.articleId },
            });
            break;
          case NotificationType.BeneficiaryAccepted:
          case NotificationType.BeneficiaryDeleted:
          case NotificationType.BeneficiaryRejected:
          case NotificationType.BeneficiaryRejectedFollowUp:
            void navigate({
              to: '/account/beneficiaries',
            });
            break;
          case NotificationType.DocumentPublished:
            void navigate({
              to: '/home/documents/$folderId',
              params: { folderId: data.folderId },
            });
            break;
          case NotificationType.OfferPublished:
            void navigate({
              to: '/explore/$campaignId',
              params: { campaignId: data.campaignId },
            });
            break;
          case NotificationType.PublicationPublished:
            void navigate({
              to: '/feed',
            });
            break;
          case NotificationType.UserEmailChanged:
            void navigate({
              to: '/account',
            });
            break;
          case NotificationType.SubventionPublished:
            void navigate({
              to: '/advantages',
            });
            break;
          default:
            void navigate({ to: '/home' });
        }
      },
    );
    return () => {
      PushNotifications.removeAllListeners();
    };
  }, [pushTokenUpsert, navigate]);

  useEffect(() => {
    if (!isMobileNative()) {
      return;
    }
    if (viewer) {
      registerNotifications();
    } else {
      PushNotifications.removeAllListeners();
    }
  }, [viewer]);

  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('[notif]: User denied permissions!');
    }

    await PushNotifications.register();
  };
};
