import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useApolloClient } from '@apollo/client';
import { env } from '@env';
import {
  Button,
  Form,
  FormInputPassword,
  FormInputText,
  FormSubmitHandler,
  useForm,
  ValuesFromZ,
} from '@happypal-tech/design-system';
import { handleApiError } from '@src/features-new/utils/handleApiError';
import { useAnalytics } from '@src/hooks/use-analytics';
import { Link, useNavigate } from '@tanstack/react-router';
import { KeyboardAvoidingView } from '@utils/KeyboardAware';
import axios from 'axios';
import { z } from 'zod';

type LoginFormProps = {
  email?: string;
  redirect?: string;
};

const REGEX_MAIL = /^[^@]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]+$/;

const LoginFormValidationSchema = () => {
  const { t } = useTranslation('auth', {
    keyPrefix: 'components.LoginForm.form.fields',
  });

  return z.object({
    email: z
      .string()
      .min(1, {
        message: t('email.errors.required_error'),
      })
      .regex(REGEX_MAIL, {
        message: t('email.errors.invalid_error'),
      }),
    password: z.string({
      required_error: t('password.errors.required_error'),
    }),
  });
};

export type LoginFormFieldValues = ValuesFromZ<
  ReturnType<typeof LoginFormValidationSchema>
>;

export const LoginForm = (props: LoginFormProps) => {
  const { email: defaultEmail, redirect } = props;
  const { t } = useTranslation('auth', {
    keyPrefix: 'components.LoginForm',
  });
  const { resetStore } = useApolloClient();
  const { track } = useAnalytics();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const form = useForm({
    validationSchema: LoginFormValidationSchema(),
    defaultValues: {
      email: defaultEmail,
    },
  });

  const { clearErrors, setError } = form;

  const email = form.watch('email');

  const onSubmitHandler: FormSubmitHandler<LoginFormFieldValues> = async ({
    email,
    password,
  }) => {
    setIsLoading(true);
    clearErrors();

    try {
      await axios.post(
        `${env.REACT_APP_API_URL}/v1/authentication/login`,
        {
          email: email.trim(),
          password: password,
        },
        {
          withCredentials: true,
        },
      );

      await resetStore();
      setIsLoading(false);
      return redirect
        ? (window.location.href = redirect)
        : navigate({
            to: '/home',
          });
    } catch (error: unknown) {
      setIsLoading(false);
      handleApiError(error, {
        default: () =>
          setError('password', { message: t('form.api.errors.default') }),
        'auth/2fa-missing': () =>
          navigate({
            to: '/auth/two-factor',
            state: (prev) => ({
              ...prev,
              email: email.trim(),
              password: password,
            }),
          }),
        'auth/ip-confirmation-missing': () =>
          navigate({
            to: '/auth/ip-validation',
            state: (prev) => ({
              ...prev,
              email: email.trim(),
              password: password,
            }),
          }),
        'auth/invalid-credentials': () =>
          setError('password', {
            message: t('form.api.errors.invalidCredentials'),
          }),
        'auth/user-disabled': () =>
          setError('password', { message: t('form.api.errors.userDisabled') }),
        'auth/user-not-found': () =>
          setError('password', { message: t('form.api.errors.userNotFound') }),
        'auth/user-not-migrated': () =>
          setError('password', { message: t('form.api.errors.userNotFound') }),
        'auth/invalid-password': () =>
          setError('password', {
            message: t('form.api.errors.invalidPassword'),
          }),
        'auth/company-sso-exists': () =>
          setError('password', {
            message: t('form.api.errors.companySsoExists'),
          }),
        'auth/2fa-invalid-token': () =>
          setError('password', {
            message: t('form.api.errors.invalidCredentials'),
          }),
      });
    }
  };

  return (
    <KeyboardAvoidingView noContainer paddingBottom={100}>
      <Form
        form={form}
        className="flex flex-col h-full justify-between md:justify-normal md:gap-8"
        onValid={onSubmitHandler}
      >
        <div className="flex flex-col gap-6">
          <Form.Field name="email" label={t('form.fields.email.label')}>
            <FormInputText
              autoComplete="username"
              type="email"
              placeholder={t('form.fields.email.placeholder')}
            />
          </Form.Field>
          <Form.Field name="password" label={t('form.fields.password.label')}>
            <FormInputPassword
              autoComplete="current-password"
              placeholder={t('form.fields.password.placeholder')}
            />
          </Form.Field>
        </div>

        <div className="flex w-full self-end justify-between md:flex-row gap-4 flex-col-reverse">
          <Link
            onClick={() =>
              track({
                type: 'forgot password',
              })
            }
            to="/auth/forgotten-password"
            state={{ email }}
          >
            <Button color="primary" variant="ghost" className="hidden md:flex">
              {t('form.forgottenPassword')}
            </Button>
            <Button color="neutral" variant="ghost" fluid className="md:hidden">
              {t('form.forgotMyPassword')}
            </Button>
          </Link>
          <Button type="submit" loading={isLoading}>
            {t('form.submit')}
          </Button>
        </div>
      </Form>
    </KeyboardAvoidingView>
  );
};
