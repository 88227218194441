import { useEffect } from 'react';

import { StatusBar, Style } from '@capacitor/status-bar';
import { isMobileNative } from '@src/utils/isMobileNative';

export const useStatusBar = () => {
  useEffect(() => {
    if (!isMobileNative()) {
      return;
    }

    const setStatusBarStyleLight = async () => {
      await StatusBar.setStyle({ style: Style.Light });
      await StatusBar.setBackgroundColor({ color: '#FFFFFF' });
    };
    (async () => {
      await setStatusBarStyleLight();
    })();
  }, []);
};
