import { useCallback, useEffect, useMemo, useState } from 'react';

import { env } from '@env';
import { useCampaignIndex } from '@src/features-new/catalog/hooks/useCampaignIndex';
import {
  useCampaignIndexNamesSuspenseQuery,
  useUserAlgoliaSecuredKeyApiSuspenseQuery,
} from '@src/routes/_authenticated-layout/~route.generated';
import { SearchIndex } from 'algoliasearch/dist/algoliasearch-lite';
import algoliasearch from 'algoliasearch/lite';
import { SearchResponse } from 'instantsearch.js';
import { debounce } from 'radash';

type SearchOptions = Parameters<SearchIndex['search']>[1];

export const useSearch = <T,>({
  query,
  debounce: delay = 400,
  skipSearch,
  options,
}: {
  query: string | undefined;
  debounce?: number;
  skipSearch?: boolean;
  options?: SearchOptions;
}) => {
  const { data: securedApiKeyData } =
    useUserAlgoliaSecuredKeyApiSuspenseQuery();
  const { data } = useCampaignIndexNamesSuspenseQuery();

  const campaignIndex = useCampaignIndex(data.campaignIndexesName);
  const indexName = campaignIndex.txt;

  const searchClient = useMemo(
    () =>
      algoliasearch(
        env.REACT_APP_ALGOLIA_APP_ID,
        securedApiKeyData.userAlgoliaSecuredKeyAPi,
      ).initIndex(indexName),
    [securedApiKeyData.userAlgoliaSecuredKeyAPi, indexName],
  );

  type SearchState = {
    loading: boolean;
    query: string;
    result?: SearchResponse<T>;
  };

  const [results, setResult] = useState<Record<string, SearchState>>({});

  const search = async (query: string) => {
    setResult((old) => {
      const value = old[query] || {};
      return {
        ...old,
        [query]: { ...value, loading: true, query },
      };
    });
    const result = await searchClient.search<T>(query, options);
    setResult((old) => ({
      ...old,
      [query]: { query, result, loading: false },
    }));
  };

  const currentState = query !== undefined ? results[query] : null;

  const [displayedState, setDisplayedState] = useState<SearchState | null>();

  useEffect(() => {
    if (currentState?.loading === false) {
      setDisplayedState(currentState);
    }
  }, [currentState]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce({ delay }, search), []);

  useEffect(() => {
    if (!skipSearch && query !== undefined) {
      debouncedSearch(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, skipSearch]);

  return {
    loading: currentState?.loading,
    ...displayedState?.result,
  };
};
