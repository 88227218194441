import React, {
  createContext,
  DependencyList,
  FocusEvent,
  forwardRef,
  MutableRefObject,
  PropsWithChildren,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';

import type { PluginListenerHandle } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { useEffectOnce } from '@src/hooks/use-effect-once';
import { isMobileNative } from '@utils/isMobileNative';
import { safeAreaPadding } from '@utils/styleUtils';
import { motion, useAnimate } from 'framer-motion';
import { last } from 'radash';

type Scroller = (height: number) => void;

type KeyboardContext = {
  scroll: (height: number) => void;
  registerScroller: (scrollview: Scroller) => () => void;
};

export const DEFAULT_BOTTOM_PADDING = 12;

const ContextImpl = createContext<KeyboardContext>({
  scroll: () => {},
  registerScroller: (_sv) => () => {},
});

const Context = (props: PropsWithChildren) => {
  const { children } = props;
  const [scope, animate] = useAnimate();

  const initialScroller = (height: number) => {
    animate(scope.current, { height });
    window.scrollBy({ top: height, behavior: 'smooth' });
  };

  const scrollers = useRef<Scroller[]>([initialScroller]);

  const scroll = (height: number) => {
    const scroller = last(scrollers.current);
    scroller?.(height);
  };

  const registerScroller = (sv: Scroller) => {
    scrollers.current.push(sv);
    return () => {
      const i = scrollers.current.indexOf(sv);
      if (i >= 0) {
        scrollers.current.splice(i, 1);
      }
    };
  };

  return (
    <ContextImpl.Provider value={{ scroll, registerScroller }}>
      {children}
      <motion.div ref={scope} />
    </ContextImpl.Provider>
  );
};

/**
 * Globally listen to DS input event and scroll the view so the input remains visible
 */
export const useListenToDSFocusEvent = () => {
  const { onFocus } = useEnsureInputVisible();
  const onFocusAdapted = useCallback(
    (ev: CustomEvent<FocusEvent<HTMLInputElement>>) => {
      return onFocus(ev.detail);
    },
    [onFocus],
  );

  useEffect(() => {
    window.addEventListener('happypal-input-focus', onFocusAdapted);
    return () => {
      // window.removeEventListener('happypal-input-focus', onFocusAdapted);
    };
  }, [onFocusAdapted]);
};

type KeyboardAvoidingScrollViewProps = PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement> & {
    paddingBottom?: number;
    noContainer?: boolean;
  }
>;
/**
 * Move view content up when keyboard opens
 */
export const KeyboardAvoidingView = (
  props: KeyboardAvoidingScrollViewProps,
) => {
  const {
    paddingBottom = DEFAULT_BOTTOM_PADDING,
    children,
    noContainer,
    ...others
  } = props;
  const localRef = useRef<HTMLDivElement>(null);
  const [scope, animate] = useAnimate();

  useSubscribe(
    () =>
      Keyboard.addListener('keyboardWillShow', (info) => {
        const height =
          info.keyboardHeight - safeAreaPadding('bottom') + paddingBottom;
        animate(scope.current, { height });
      }),
    [paddingBottom],
  );

  useSubscribe(
    () =>
      Keyboard.addListener('keyboardWillHide', () => {
        animate(scope.current, { height: 0 });
      }),
    [],
  );

  return noContainer ? (
    <>
      {children}
      <motion.div ref={scope} />
    </>
  ) : (
    <div ref={localRef} {...others}>
      {children}
      <motion.div ref={scope} />
    </div>
  );
};

export const KeyboardAwareScrollView = forwardRef<
  HTMLDivElement,
  KeyboardAvoidingScrollViewProps
>((props, ref) => {
  const { children, ...others } = props;
  const localRef = useRef<HTMLDivElement>(null);
  const [scope, animate] = useAnimate();

  const { registerScroller } = useContext(ContextImpl);

  useEffectOnce(() =>
    registerScroller((height: number) => {
      animate(scope.current, { height });
      setTimeout(() => {
        localRef.current?.scrollBy({ top: height, behavior: 'smooth' });
      }, 400);
    }),
  );

  useSubscribe(
    () =>
      Keyboard.addListener('keyboardWillHide', () => {
        animate(scope.current, { height: 0 });
      }),
    [],
  );

  return (
    <div ref={assignRefs(localRef, ref)} {...others}>
      {children}
      <motion.div ref={scope} />
    </div>
  );
});

const assignRefs = <T,>(...refs: Ref<T | null>[]) => {
  return (node: T | null) => {
    refs.forEach((r) => {
      if (typeof r === 'function') {
        r(node);
      } else if (r) {
        (r as MutableRefObject<T | null>).current = node;
      }
    });
  };
};

export const useSubscribe = (
  subscribe: () => Promise<PluginListenerHandle>,
  deps?: DependencyList,
) => {
  const subscribing = useRef(false);

  useEffect(() => {
    if (!isMobileNative()) {
      return;
    }
    if (subscribing.current) {
      return;
    }

    let subscription: PluginListenerHandle;
    subscribing.current = true;

    subscribe().then((handle) => {
      subscription = handle;
      subscribing.current = false;
    });
    return () => {
      if (subscription) {
        subscription.remove();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export const useEnsureInputVisible = () => {
  const ref = useRef({ bottom: -1, keyboardHeight: -1 });

  // animate the closest view
  const { scroll } = useContext(ContextImpl);

  const reset = () => (ref.current = { bottom: -1, keyboardHeight: -1 });

  const scrollMaybe = () => {
    const { bottom, keyboardHeight } = ref.current;
    if (bottom > 0 && keyboardHeight > 0) {
      const innerHeight = window.innerHeight;
      const height = Math.max(
        0,
        bottom + DEFAULT_BOTTOM_PADDING + keyboardHeight - innerHeight,
      );
      scroll(height);
      reset();
    }
  };

  useSubscribe(
    () =>
      Keyboard.addListener('keyboardWillShow', (info) => {
        ref.current.keyboardHeight = info.keyboardHeight;
        scrollMaybe();
      }),
    [],
  );

  useSubscribe(() => Keyboard.addListener('keyboardWillHide', reset), []);

  const onFocus = useCallback(
    (event: FocusEvent<HTMLElement>) =>
      (ref.current.bottom = event.target.getBoundingClientRect().bottom),
    [],
  );

  return {
    onFocus,
  };
};

export default Context;
