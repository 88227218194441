import { useListenToDeeplinks } from '@src/features-new/core/deeplinks/useListenToDeepLinks';
import { usePushNotifications } from '@src/features-new/core/notifications/useNotifications';
import { useStatusBar } from '@src/features-new/core/statusbar/UseStatusBar';
import { ConnectedUserFragment } from '@src/index.generated';
import { useListenToDSFocusEvent } from '@utils/KeyboardAware';

interface AppEngineProps {
  viewer: ConnectedUserFragment | null;
}
export const AppEngine = ({ viewer }: AppEngineProps) => {
  usePushNotifications(viewer);
  useListenToDeeplinks();
  useStatusBar();
  useListenToDSFocusEvent();

  return null;
};
