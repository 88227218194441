import { Button } from '@components/atoms/Button';
import { Badge } from '@components/molecules/Badge/Badge';
import { Link } from '@tanstack/react-router';

import { useViewerCartButtonQuery } from './CartButton.generated';

type CartButtonProps = {
  mobileMode?: boolean;
  isBackgroundDark?: boolean;
  className?: string;
};

export const CartButton = ({
  isBackgroundDark,
  className,
}: CartButtonProps) => {
  const { data } = useViewerCartButtonQuery();

  const cartItemCount = data?.viewer?.activeCart.cartItemsQuantity ?? 0;

  return (
    <Link className={className} to="/cart">
      <div className="mr-2">
        <Badge value={cartItemCount} isOutline={false} position="cart">
          <Button
            iconOnly="cart"
            variant="ghost"
            color={isBackgroundDark ? 'primary' : 'neutral'}
          />
        </Badge>
      </div>
    </Link>
  );
};
