const SAPMapping = {
  top: '--sat',
  left: '--sal',
  bottom: '--sab',
  right: '--sar',
};

export function safeAreaPadding(edge: 'top' | 'left' | 'bottom' | 'right') {
  const css = getComputedStyle(document.documentElement).getPropertyValue(
    SAPMapping[edge],
  );
  return parseInt(css, 10);
}
