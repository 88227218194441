import { env } from '@env';
import axios from 'axios';

export interface SsoProvider {
  id: string;
  name: string;
  url: string;
}

export async function getProviders(code: string) {
  try {
    const res = await axios.get<SsoProvider[]>(
      `${env.REACT_APP_API_URL}/v1/authentication/${code}/sso-providers`,
    );
    const providers = res.data as SsoProvider[];

    return { providers };
  } catch (error) {
    return { error };
  }
}
