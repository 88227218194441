import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DetailsKeyPoints,
  DetailsKeyPointsProps,
} from '@src/components/molecules/DetailsKeyPoints/DetailsKeyPoints';
import { getOfferPictoInfos } from '@utils/offerUtils';
import { unique } from 'radash';

import { CampaignKeyPointsFragment } from './CampaignKeyPoints.generated';

type CampaignKeyPointsProps = {
  campaign: CampaignKeyPointsFragment;
  hasRemainingDiscountSubventions: boolean;
};

export function CampaignKeyPoints(props: CampaignKeyPointsProps) {
  const { campaign, hasRemainingDiscountSubventions } = props;
  const { t } = useTranslation();

  const offerTypeTags = useMemo(() => {
    const tags: DetailsKeyPointsProps['offerTypeTags'] = [];
    const uniqOfferTypes = unique(
      campaign.offersPagination.nodes.map((offer) => offer.__typename),
    );

    uniqOfferTypes.forEach((offerType) => {
      const offerPictoInfos = getOfferPictoInfos(offerType, t);

      tags.push({
        label: offerPictoInfos.t_key,
        type: offerPictoInfos.icon,
      });
    });

    return tags;
  }, [campaign.offersPagination.nodes, t]);

  const subventionTag: DetailsKeyPointsProps['subventionTag'] | null =
    hasRemainingDiscountSubventions
      ? {
          label: t('common:subsidized', 'Subventionnée'),
          type: 'SparksOutline',
        }
      : null;

  return (
    <DetailsKeyPoints
      title={t('campaignDetails.details', "Détails de l'offre")}
      offerTypeTags={offerTypeTags}
      subventionTag={subventionTag}
    />
  );
}
