import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Spin } from '@happypal-tech/design-system';
import { useTimeout } from '@hooks/use-timeout';
import { PageTitle } from '@src/features-new/auth/components/PageTitle';
import { SSOError } from '@src/routes/auth/~SSOError';
import { getPublicSsoProviderUrl } from '@src/routes/auth/sso.$slug/~SSOPage';
import { createFileRoute } from '@tanstack/react-router';
import { generateCodeVerifier, storeCodeVerifier } from '@utils/auth';
import { z } from 'zod';

export const Route = createFileRoute('/auth/sso/prepare')({
  component: SSOPrepare,
  validateSearch: z.object({ url: z.string(), t: z.number() }),
  errorComponent: SSOError,
});

function SSOPrepare() {
  const { t } = useTranslation('auth', {
    keyPrefix: 'auth.ssoPreparePage',
  });

  const search = Route.useSearch();
  const url = decodeURIComponent(search.url);
  const ts = search.t;

  // starts a new sso login flow, with a code challenge
  const startSSO = useCallback(() => {
    generateCodeVerifier().then(({ codeChallenge, codeVerifier }) => {
      storeCodeVerifier(codeVerifier);
      const ssoUrl = getPublicSsoProviderUrl(url, codeChallenge);
      window.location.assign(ssoUrl);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, ts]);

  useTimeout(startSSO, 100);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <Container className="p-8">
        <PageTitle title={t('title')} />
        <div className="flex items-center justify-center p-4 w-full">
          <Spin />
        </div>
      </Container>
    </div>
  );
}
