import { z } from '@happypal-tech/design-system';
import { createFileRoute, Navigate } from '@tanstack/react-router';

const Redirect = () => {
  let { to } = Route.useSearch();

  if (!to) return <Navigate to="/" replace />;

  to = decodeURIComponent(to);

  if (to.startsWith('/')) {
    return <Navigate to={to} replace />;
  }
  return window.location.replace(to);
};

export const Route = createFileRoute('/redirect')({
  component: Redirect,
  validateSearch: z.object({
    to: z.string().optional(),
  }),
});
