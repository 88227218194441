import React, { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { IntercomProvider } from 'react-use-intercom';

import 'globalthis/polyfill'; // polyfill for iOS 12 and under
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { Loader } from '@components/atoms/Loader';
import { UpdateModal } from '@components/organisms/UpdateModal';
import { client } from '@graphql/client';
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { DefaultNotFound } from '@src/features-new/core/DefaultNotFound/DefaultNotFound';
import { isMobileNative } from '@src/utils/isMobileNative';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import KeyboardAware from '@utils/KeyboardAware';

import { FloatingProvider } from './features-new/design-system/components/data-display/FloatingItem/FloatingProvider';
import i18next from './i18n/i18n';
import { LocalizationProvider } from './i18n/localization.context';
import { env } from './env';
import { useRootIndexQuery } from './index.generated';
import reportWebVitals from './reportWebVitals';
// Import the generated route tree
import { routeTree } from './routes.generated';

import './index.css';
import './features-new/design-system/index.css';

async function initNewRelic() {
  if (!Capacitor) {
    return;
  }

  if (
    Capacitor.getPlatform() !== 'ios' &&
    Capacitor.getPlatform() !== 'android'
  ) {
    return;
  }

  const newrelic = await import('@newrelic/newrelic-capacitor-plugin');
  let appToken;

  if (Capacitor.getPlatform() === 'ios') {
    appToken = env.REACT_APP_NEWRELIC_CAPACITOR_APP_TOKEN_IOS;
  } else {
    appToken = env.REACT_APP_NEWRELIC_CAPACITOR_APP_TOKEN_ANDROID;
  }

  newrelic.NewRelicCapacitorPlugin.start({
    appKey: appToken,
    agentConfiguration: {
      analyticsEventEnabled: true,
      webViewInstrumentation: true,
      crashReportingEnabled: true,
      interactionTracingEnabled: true,
      networkRequestEnabled: true,
      networkErrorRequestEnabled: true,
      httpResponseBodyCaptureEnabled: true,
      loggingEnabled: true,
      logLevel: newrelic.NREnums.LogLevel.INFO,
      sendConsoleEvents: true,
    },
  });
}

initNewRelic();

if (isMobileNative()) {
  CapacitorUpdater.notifyAppReady();
}

Sentry.init({
  enabled: !!env.REACT_APP_SENTRY_DSN,
  dsn: env.REACT_APP_SENTRY_DSN,
  environment: env.REACT_APP_SENTRY_ENV,
  release: env.REACT_APP_SENTRY_RELEASE,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new CaptureConsole({
      levels: ['warn', 'error'],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE,
});

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  context: {
    apolloClient: undefined!,
    viewer: null,
    company: null,
  },
  defaultNotFoundComponent: DefaultNotFound,
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

declare module '@tanstack/react-router' {
  interface HistoryState {
    email?: string;
    password?: string;
  }
}

function InnerApp() {
  const apolloClient = useApolloClient();

  const { data, loading } = useRootIndexQuery();

  if (loading) return null;
  const viewer = data?.viewer ?? null;

  return (
    <>
      <AppEngine />
      <RouterProvider
        defaultPreload={false}
        router={router}
        context={{ apolloClient, viewer }}
      />
    </>
  );
}

const AppEngine = () => {
  return (
    <>
      <UpdateModal />
    </>
  );
};

function App() {
  return (
    <Suspense
      fallback={
        <div className="flex h-screen items-center">
          <Loader />
        </div>
      }
    >
      <KeyboardAware>
        <IntercomProvider
          apiBase="https://api-iam.eu.intercom.io"
          appId={env.REACT_APP_INTERCOM_ID}
          autoBoot
          autoBootProps={{
            actionColor: '#02B6A9',
            backgroundColor: '#02B6A9',
          }}
        >
          <LocalizationProvider i18n={i18next}>
            <ApolloProvider client={client}>
              <FloatingProvider>
                <InnerApp />
              </FloatingProvider>
            </ApolloProvider>
          </LocalizationProvider>
        </IntercomProvider>
      </KeyboardAware>
    </Suspense>
  );
}

// Render the app
const rootElement = document.getElementById('root')!;

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
