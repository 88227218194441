import {
  createContext,
  Dispatch,
  Fragment,
  SetStateAction,
  useState,
} from 'react';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';

import {
  CartConfirmationSlide,
  CartConfirmationSlideContent,
} from '@components/organisms/CartConfirmationSlide/CartConfirmationSlide';
import {
  Icon,
  Modal,
  Typography,
  useBreakpoint,
} from '@happypal-tech/design-system';
import { Separator } from '@src/components/atoms/Separator';
import { Drawer } from '@src/components/organisms/Drawer';

import { OfferItem } from '../OfferItem/OfferItem';

import {
  CampaignOfferActiveSubventionFragment,
  useCampaignOffersQuery,
} from './CampaignOffers.generated';

type CampaignOffersProps = {
  campaignId: string;
  activeSubventionEdge?: CampaignOfferActiveSubventionFragment;
  selectedCity?: string | null;
  queryID?: string;
};

type CampaignOffersContextType = {
  currentCartConfirmationSlide: CartConfirmationSlideContent | null;
  setCurrentCartConfirmationSlide: Dispatch<
    SetStateAction<CartConfirmationSlideContent | null>
  >;
};

export const CampaignOffersContext = createContext<CampaignOffersContextType>(
  null as unknown as CampaignOffersContextType,
);

// Separated in two FCs because we don't want the animation to trigger on query rerenders
export function CampaignOffers(props: CampaignOffersProps) {
  const { campaignId, activeSubventionEdge, selectedCity, queryID } = props;
  const isHigherThanMobile = useBreakpoint('tablet');
  const isMobile = !isHigherThanMobile;
  const [currentCartConfirmationSlide, setCurrentCartConfirmationSlide] =
    useState<CartConfirmationSlideContent | null>(null);
  const { t } = useTranslation();

  const { data, loading } = useCampaignOffersQuery({
    variables: {
      campaignId,
      subventionIncluded: !!activeSubventionEdge,
      subventionId: activeSubventionEdge?.node.id,
      filter: selectedCity
        ? { cities: { include: [selectedCity] } }
        : undefined,
    },
  });

  const offers = data?.catalog.campaign?.offersPagination?.nodes || [];
  const filteredOffers = offers.filter(
    (o) => o.subventionsPagination?.totalCount === 1,
  );

  const handleOpenChange = (nextOpened: boolean) => {
    if (nextOpened === false) {
      setCurrentCartConfirmationSlide(null);
      return;
    }
  };

  if (loading) {
    return <SkeletonCampaignOffersList />;
  }

  return (
    <CampaignOffersContext.Provider
      value={{
        currentCartConfirmationSlide,
        setCurrentCartConfirmationSlide,
      }}
    >
      <Modal.Drawer
        title={
          <div className="flex gap-2 items-center px-6">
            <Icon
              name="CheckCircleOutline"
              size={16}
              className="text-primary"
            />
            <Typography type="heading-4" bold>
              {t('cart.cartConfirmationSlide.responsiveTitle')}
            </Typography>
          </div>
        }
        description={t('cart.cartConfirmationSlide.title')}
        descriptionHidden
        opened={!!currentCartConfirmationSlide && isMobile}
        onOpenChange={handleOpenChange}
        rootClassName="z-drawer"
        childrenClassName="mt-0"
      >
        <CartConfirmationSlide />
      </Modal.Drawer>

      <Drawer
        isOpen={!!currentCartConfirmationSlide && !isMobile}
        onCloseModal={() => setCurrentCartConfirmationSlide(null)}
      >
        <CartConfirmationSlide />
      </Drawer>

      {activeSubventionEdge &&
        filteredOffers.map((offer, i) => (
          <Fragment key={offer.id}>
            {i > 0 && <Separator top={10} bottom={10} />}
            <OfferItem
              queryID={queryID}
              campaignId={campaignId}
              subventionEdge={activeSubventionEdge}
              item={offer}
            />
          </Fragment>
        ))}
      {activeSubventionEdge && filteredOffers.length > 0 && (
        <Separator top={10} bottom={10} />
      )}
      {offers.map((offer, i) => (
        <Fragment key={offer.id}>
          {i > 0 && <Separator top={10} bottom={10} />}
          <OfferItem campaignId={campaignId} queryID={queryID} item={offer} />
        </Fragment>
      ))}
    </CampaignOffersContext.Provider>
  );
}

const SkeletonCampaignOffersList = () => {
  return (
    <ContentLoader
      speed={1}
      width="100%"
      height={420}
      backgroundColor="#F8F9FB"
      foregroundColor="#CCCFE1"
    >
      <rect x="0" y="0" rx="4" ry="4" width="50%" height="24" />
      <rect x="0" y="40" rx="12" ry="12" width="88" height="34" />
      <rect x="0" y="88" rx="4" ry="4" width="100%" height="146" />
      <circle cx="40%" cy="288" r="17" />
      <circle cx="60%" cy="288" r="17" />
      <rect x="0" y="328" rx="4" ry="4" width="100%" height="40" />
    </ContentLoader>
  );
};
