import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Separator } from '@components/atoms/Separator';
import { CampaignOffersContext } from '@components/organisms/CampaignOffers/CampaignOffers';
import { ProductType } from '@graphql/generated/types';
import { Button, Icon, Typography } from '@happypal-tech/design-system';
import { Link } from '@tanstack/react-router';
import { useFormatPrice } from '@utils/hooks/useFormatPrice';
import { cx } from 'class-variance-authority';

import { CartConfirmationSlideFragment } from './CartConfirmationSlide.generated';

export type CartConfirmationSlideContent = {
  item: CartConfirmationSlideFragment;
  totalCount: number;
};

type CartConfirmationSlideProps = {
  className?: string;
};

export const CartConfirmationSlide = ({
  className,
}: CartConfirmationSlideProps) => {
  const { currentCartConfirmationSlide, setCurrentCartConfirmationSlide } =
    useContext(CampaignOffersContext);
  const { t } = useTranslation();
  const formatPrice = useFormatPrice();

  if (!currentCartConfirmationSlide) return null;

  const { item, totalCount } = currentCartConfirmationSlide;
  const brand = item.productVariant.product.brand;

  return (
    <div className={cx(className, 'px-4 md:px-6 md:pt-16')}>
      <div className="flex gap-2 items-center">
        <Icon
          name="CheckCircleOutline"
          size={24}
          className="hidden md:block text-primary"
        />
        <Typography type="subtitle" bold className="hidden md:block">
          {t('cart.cartConfirmationSlide.title')}
        </Typography>
      </div>

      <Separator />

      <Typography type="subtitle" semibold>
        {item.productVariant.product.name}
      </Typography>
      <Typography type="body" className="hidden md:block mb-2">
        {brand.name}
      </Typography>
      {item.productVariant.product.type === ProductType.GiftCard && (
        <Typography type="body">
          {t('cart.cartConfirmationSlide.giftCard')}{' '}
          <span className="font-bold">{formatPrice(item.amounts.amount)}.</span>
        </Typography>
      )}
      <Typography type="body" className="text-neutral-dark">
        {t('cart.cartConfirmationSlide.quantity')} : {item.quantity}
      </Typography>

      <div className="mt-10 flex flex-col md:flex-row md:justify-between items-center gap-4">
        <Button
          variant="stroke"
          onClick={() => setCurrentCartConfirmationSlide(null)}
          fluid
        >
          {t('cart.cartConfirmationSlide.continue')}
        </Button>
        <Link to="/cart" className="w-full">
          <Button fluid>
            {t('cart.cartConfirmationSlide.goToCart')} ({totalCount})
          </Button>
        </Link>
      </div>
    </div>
  );
};
