import { ReactNode } from 'react';

import { Typography } from '@happypal-tech/design-system';

type PageTitleProps = {
  title: string | ReactNode;
};
export const PageTitle = (props: PageTitleProps) => {
  return (
    <Typography
      type="heading-2"
      asChild
      bold
      className="text-neutral-darkest text-left"
    >
      <h2>{props.title}</h2>
    </Typography>
  );
};
