import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useApolloClient } from '@apollo/client';
import { env } from '@env';
import { Container, Spin, z } from '@happypal-tech/design-system';
import { PageTitle } from '@src/features-new/auth/components/PageTitle';
import { createFileRoute, useRouter } from '@tanstack/react-router';
import axios from 'axios';
import Url from 'url-parse';

const DEFAULT_ROUTE = '/home';

const SsoFederated = () => {
  const search = Route.useSearch();
  const { token } = search;
  const redirectUrl = decodeURIComponent(search.redirectTo || DEFAULT_ROUTE);
  const router = useRouter();
  const { t } = useTranslation('auth', {
    keyPrefix: 'auth.ssoPreparePage',
  });
  const { resetStore } = useApolloClient();

  const login = async () => {
    await axios.get(`${env.REACT_APP_API_URL}/v1/sessions/login/${token}`, {
      withCredentials: true,
      timeout: 3000,
    });
  };

  const redirect = () => {
    const url = new Url(redirectUrl);
    const slug = `${url.pathname}${url.query}${url.hash}`;
    router.history.replace(slug);
  };

  /**
   * https://www.notion.so/happypal/SSO-hell-68edc3ab17a64ac2bb5bb357142e8732?pvs=4#5fd70e750ae4416a8f1c6604ee920b6d
   * On the Web, user is probably already connected (redirected with a set-cookie), and this effect log the user 'again'.
   * On capacitor, user is not logged and this is effect log the user in.
   * On react-native, the webview navigation provides the cookie. This effect might be run, but not useful
   */
  useEffect(() => {
    login().then(resetStore).finally(redirect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, redirectUrl]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <Container className="p-8">
        <PageTitle title={t('title')} />
        <div className="flex items-center justify-center p-4 w-full">
          <Spin />
        </div>
      </Container>
    </div>
  );
};

export const Route = createFileRoute('/sso/federated')({
  component: SsoFederated,
  validateSearch: z.object({
    redirectTo: z.string().optional(),
    token: z.string().optional(),
  }),
});
