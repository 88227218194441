import { useEffect } from 'react';

import { App, URLOpenListenerEvent } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';
import { useRouter } from '@tanstack/react-router';
import { isMobileNative } from '@utils/isMobileNative';
import Url from 'url-parse';

export const useListenToDeeplinks = () => {
  const router = useRouter();

  useEffect(() => {
    if (!isMobileNative()) {
      return;
    }
    let handler: PluginListenerHandle | undefined;

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // eslint-disable-next-line no-console
      console.info('deeplink received', event.url);
      // URL parses url with custom protocols differently on ios and android
      // using url-parse for consistency
      const url = new Url(event.url);
      const slug = `${url.pathname}${url.query}${url.hash}`;
      if (slug) {
        router.history.push(slug);
      }
    }).then((l) => {
      handler = l;
    });

    return () => {
      handler?.remove();
    };
  }, [router]);
};
