import { useTranslation } from 'react-i18next';

import PalQuestion3D from '@assets/images/PalQuestion3D.png';
import {
  Alert,
  Button,
  Picture,
  Typography,
} from '@happypal-tech/design-system';
import { ErrorComponentProps, isNotFound, Link } from '@tanstack/react-router';
import { isAxiosError } from 'axios';

const UNKNOWN_ERROR_DETAILS = 'Unknown error';

function getErrorDetails(error: unknown): string {
  if (!error) {
    return UNKNOWN_ERROR_DETAILS;
  }
  if (isNotFound(error)) {
    return 'Not found';
  }
  if (isAxiosError(error)) {
    if (error.response) {
      return JSON.stringify(error.response.data);
    } else {
      return error.message;
    }
  }
  if (
    typeof error === 'object' &&
    'code' in error &&
    typeof error.code === 'string'
  ) {
    return error.code;
  }
  if (error instanceof Error) {
    return error.message;
  }
  return UNKNOWN_ERROR_DETAILS;
}

export const SSOError = ({ error }: ErrorComponentProps) => {
  const { t } = useTranslation('auth', {
    keyPrefix: 'auth.ssoError',
  });
  return (
    <div className="absolute w-full h-full flex items-center justify-center">
      <div className="max-w-[580px] text-center flex items-center flex-col">
        <Picture
          alt="Pal Question"
          className="w-[196px] h-[196px]"
          fit="cover"
          src={PalQuestion3D}
        />
        <Typography asChild type="heading-3" bold className="mt-4">
          <h1>{t('title')}</h1>
        </Typography>
        <Typography asChild type="body" className="mt-2">
          <p>{t('text')}</p>
        </Typography>
        <Alert variant="ghost" type="danger" className="mt-4 w-full text-start">
          {getErrorDetails(error)}
        </Alert>
        <Link to="/auth/login/company-code" className="mt-8">
          <Button iconLeft="ArrowLeftOutline">{t('cta')}</Button>
        </Link>
      </div>
    </div>
  );
};
