import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigationHistory } from '@contexts/NavigationHistoryProvider/NavigationHistoryProvider';
import { Button, Typography } from '@happypal-tech/design-system';
import { cn } from '@src/features-new/ui/cn';
import { routeTree } from '@src/routes.generated';
import { Link, RoutePaths } from '@tanstack/react-router';

type MobileAppHeaderBackToOrLogo =
  | {
      backTo: RoutePaths<typeof routeTree>;
      logo?: never;
      backToPrev?: never;
    }
  | {
      backTo?: never;
      logo: ReactNode;
      backToPrev?: never;
    }
  | {
      backTo?: never;
      logo?: never;
      backToPrev: RoutePaths<typeof routeTree> | boolean;
    };

type MobileAppHeaderProps = MobileAppHeaderBackToOrLogo & {
  title?: ReactNode;
  links?: ReactNode;
  className?: string;
};

export const MobileAppHeader = ({
  backTo,
  logo,
  title,
  links,
  className,
  backToPrev,
}: MobileAppHeaderProps) => {
  const { t } = useTranslation('core', {
    keyPrefix: 'components.mobileAppHeader',
  });
  const { navigateToPrev } = useNavigationHistory();

  return (
    <div
      className={'fixed top-0 inset-x-0 z-10 bg-white pt-safe md:hidden'}
      id="MobileAppHeader"
    >
      <div
        className={cn(
          'flex items-center p-2 px-4 gap-2 w-full h-15',
          className,
        )}
      >
        {backTo ? (
          <Link to={backTo}>
            <Button
              variant="ghost"
              color="neutral"
              size="medium"
              icon="ChevronLeftOutline"
              aria-label={t('back')}
            />
          </Link>
        ) : backToPrev ? (
          <Button
            variant="ghost"
            color="neutral"
            size="medium"
            icon="ChevronLeftOutline"
            aria-label={t('back')}
            onClick={() =>
              navigateToPrev(
                typeof backToPrev !== 'boolean' ? backToPrev : undefined,
              )
            }
          />
        ) : (
          <div className="w-11 h-11">{logo}</div>
        )}
        <div className="line-clamp-1 grow">
          <Typography
            className="truncate text-center"
            type="heading-4"
            asChild
            semibold
          >
            <h1>{title}</h1>
          </Typography>
        </div>
        <div className="min-w-11">{links}</div>
      </div>
    </div>
  );
};
