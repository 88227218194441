import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Capacitor } from '@capacitor/core';
import { env } from '@env';
import { Button, Modal, Typography } from '@happypal-tech/design-system';
import { get } from 'radash';
import semver from 'semver';

export const UpdateModal = () => {
  const { t } = useTranslation();
  const [versionSupport, setVersionSupport] = useState<
    'deprecated' | 'sunset' | null
  >();
  const [closed, setClosed] = useState(false);

  useEffect(() => {
    getSupport().then(setVersionSupport);
  }, []);

  const updateLink = useMemo(() => {
    const platform = Capacitor.getPlatform();
    switch (platform) {
      case 'ios':
        return env.REACT_APP_LINK_APP_IOS;
      case 'android':
        return env.REACT_APP_LINK_APP_ANDROID;
      default:
        return env.REACT_APP_APP_URL;
    }
  }, []);

  const opened = !closed && !!versionSupport;

  // user cannot close the modal if app is sunseted
  const closable = versionSupport !== 'sunset';
  return (
    <Modal.Dialog
      opened={opened}
      title={t('ModalAppUpdate.title')}
      rootClassName="z-modal"
      description={t('ModalAppUpdate.text')}
      closable={closable}
      descriptionHidden={true}
      onOpenChange={() => setClosed(true)}
    >
      <Typography type={'body'} bold={true}>
        {t('ModalAppUpdate.subtitle')}
      </Typography>
      <Typography type={'body'}>{t('ModalAppUpdate.text')}</Typography>
      <a href={updateLink} target={'_blank'} rel="noreferrer">
        <Button className={'w-full mt-6'}>{t('ModalAppUpdate.cta')}</Button>
      </a>
    </Modal.Dialog>
  );
};

const getSupport = async () => {
  const response = await fetch(env.REACT_APP_APP_URL + '/env.json');
  const data = await response.json();
  const current = env.REACT_APP_VERSION;
  const deprecated = satisfies(
    current,
    get(data, 'REACT_APP_DEPRECATED_VERSIONS') as string,
  );
  const sunset = satisfies(
    current,
    get(data, 'REACT_APP_SUNSET_VERSIONS') as string,
  );

  return sunset ? 'sunset' : deprecated ? 'deprecated' : null;
};

const satisfies = (version?: string, range?: string) => {
  if (!semver.valid(version) || !semver.validRange(range)) {
    return false;
  }
  return semver.satisfies(version!, range!);
};
