import React, {
  ChangeEventHandler,
  FocusEventHandler,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useEnsureInputVisible } from '@utils/KeyboardAware';
import { getCurrencySymbol } from '@utils/textUtils';
import { Currency } from 'dinero.js';

import './InputNumber.css';

type InputNumberProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'ref'
> & {
  noControls?: boolean;
  isFloat?: boolean;
  fractionDigits?: number;
  error?: boolean;
  currency?: Currency;
  noWheelControl?: boolean;
};

export const InputNumber = ({
  noControls = false,
  isFloat = false,
  fractionDigits = 2,
  error,
  currency,
  noWheelControl,
  onFocus,
  ...rest
}: InputNumberProps) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const inputElement = inputEl.current;
    if (noWheelControl) {
      inputElement?.addEventListener('wheel', (event) => {
        event.preventDefault();
      });
    }
    return () =>
      inputElement?.removeEventListener('wheel', (event) => {
        event.preventDefault();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { onChange } = rest;
    if (!onChange) {
      return;
    }

    let numberValue = '';

    if (e.target.value === '') {
      onChange(e);
      return;
    }

    if (isFloat) {
      const fractionIndex = e.target.value.indexOf('.');
      if (
        fractionDigits !== 0 &&
        fractionIndex !== -1 &&
        e.target.value.slice(fractionIndex + 1).length > fractionDigits
      ) {
        numberValue = parseFloat(e.target.value).toFixed(fractionDigits);
      } else {
        numberValue = e.target.value;
      }
    } else {
      numberValue = e.target.value.replace(/(,|\.).*/, '');
    }

    e.target.value = numberValue;
    e.currentTarget.value = numberValue;
    onChange(e);
  };

  const { onFocus: ensureInputVisible } = useEnsureInputVisible();

  const onInputFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    ensureInputVisible(e);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    const { min, max, onBlur } = rest;
    let numberValue = e.target.value;

    if (!onBlur) {
      if (min || max) {
        // eslint-disable-next-line no-console
        console.warn(
          'To be able to use "min" or "max" provide "onBlur" as well',
        );
      }
      return;
    }

    if (numberValue === '') {
      onBlur(e);
      return;
    }

    if (min && numberValue < min) {
      numberValue = min.toString();
    }
    if (max && numberValue > max) {
      numberValue = max.toString();
    }

    e.target.value = numberValue;
    onBlur(e);
  };

  return (
    <div className="relative">
      <input
        {...rest}
        ref={inputEl}
        type="number"
        step={isFloat ? 'any' : undefined}
        onChange={handleChange}
        onFocus={onInputFocus}
        onBlur={handleOnBlur}
        className={`inline-flex w-full items-center justify-center rounded-xl border px-4 py-3 font-body text-sm placeholder-grey outline-none transition-all duration-300 focus:placeholder-opacity-50 focus:outline-none ${
          noControls ? 'input-number-no-controls' : ''
        } ${error ? 'border-red focus:border-red' : 'focus:border-primary'}`}
      />
      {currency && inputEl.current?.value && (
        <span className="absolute right-4 top-1/2 -translate-y-1/2 transform font-body text-sm">
          {getCurrencySymbol(i18n.language, currency)}
        </span>
      )}
    </div>
  );
};
