function createRandomString() {
  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.';
  let random = '';
  const randomValues = Array.from(
    window.crypto.getRandomValues(new Uint8Array(43)),
  );
  randomValues.forEach((v) => (random += charset[v % charset.length]));
  return random;
}

function sha256(s: string) {
  return window.crypto.subtle.digest(
    { name: 'SHA-256' },
    new TextEncoder().encode(s),
  );
}

function urlEncodeB64(input: string) {
  const b64Chars: { [index: string]: string } = { '+': '-', '/': '_', '=': '' };
  return input.replace(/[+/=]/g, (m: string) => b64Chars[m] ?? '');
}

function bufferToBase64UrlEncoded(input: number[] | Uint8Array | ArrayBuffer) {
  const ie11SafeInput = new Uint8Array(input);
  return urlEncodeB64(
    window.btoa(String.fromCharCode(...Array.from(ie11SafeInput))),
  );
}

export async function generateCodeVerifier() {
  const codeVerifier = createRandomString();
  const codeChallenge = bufferToBase64UrlEncoded(await sha256(codeVerifier));
  return {
    codeVerifier,
    codeChallenge,
  };
}

const codeVerifierStorageKey = 'hp_code_verifier';

export function storeCodeVerifier(codeVerifier: string) {
  window.sessionStorage.setItem(codeVerifierStorageKey, codeVerifier);
}

export function getStoredCodeVerifier() {
  const codeVerifier = window.sessionStorage.getItem(codeVerifierStorageKey);
  window.sessionStorage.removeItem(codeVerifierStorageKey);
  return codeVerifier;
}
