import { useEffect, useMemo } from 'react';
import { InstantSearch } from 'react-instantsearch';
import { zeroRightClassName } from 'react-remove-scroll-bar';
import { useIntercom } from 'react-use-intercom';

import { env } from '@env';
import { applyThemeColor, Spin } from '@happypal-tech/design-system';
import { MobileTabBar } from '@src/components/organisms/MobileTabBar';
import { Showstopper } from '@src/features/showstopper/Showstopper';
import { useCampaignIndex } from '@src/features-new/catalog/hooks/useCampaignIndex';
import { AppFooter } from '@src/features-new/core/AppFooter/AppFooter';
import { AppHeader } from '@src/features-new/core/AppHeader/AppHeader';
import { AppMenu } from '@src/features-new/core/AppMenu/AppMenu';
import { cn } from '@src/features-new/ui/cn';
import { UserRole } from '@src/graphql/generated/types';
import { AuthenticatedContextProvider } from '@src/hooks/authenticated-context/authenticated-context';
import { useEffectOnce } from '@src/hooks/use-effect-once';
import { AlgoliaSearchInsights } from '@src/lib/algolia/search-insights';
import { AmplitudeViewer } from '@src/lib/amplitude/amplitude';
import { CustomerIO } from '@src/lib/customer-io/customer-io';
import { Hotjar } from '@src/lib/hotjar/hotjar';
import { Satismeter } from '@src/lib/satismeter/satismeter';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import algoliasearch from 'algoliasearch/lite';
import { cx } from 'class-variance-authority';
import { UiState } from 'instantsearch.js';
import { z } from 'zod';

import {
  AuthenticatedLayoutDocument,
  AuthenticatedLayoutQuery,
  AuthenticatedLayoutQueryVariables,
  useAuthenticatedLayoutSuspenseQuery,
  useCampaignIndexNamesSuspenseQuery,
  useUserAlgoliaSecuredKeyApiSuspenseQuery,
} from './~route.generated';

interface RouteState {
  q?: string;
  cat?: string[];
  lat?: string;
  lon?: string;
  radius?: string;
}

const FUTURE = { preserveSharedStateOnUnmount: true };

const AuthenticatedLayout = () => {
  const { data: authData } = useAuthenticatedLayoutSuspenseQuery();
  const viewer = authData.viewer!;
  const intercomHash = authData.viewerIntercomHash;
  const company = viewer.company!;

  const { data: securedApiKeyData } =
    useUserAlgoliaSecuredKeyApiSuspenseQuery();

  const searchClient = useMemo(
    () =>
      algoliasearch(
        env.REACT_APP_ALGOLIA_APP_ID,
        securedApiKeyData.userAlgoliaSecuredKeyAPi,
      ),
    [securedApiKeyData.userAlgoliaSecuredKeyAPi],
  );

  const { data } = useCampaignIndexNamesSuspenseQuery();

  const campaignIndex = useCampaignIndex(data.campaignIndexesName);

  const primaryColor =
    company?.featureConfig.identityCustomisation.colorPrimary ?? undefined;

  const intercom = useIntercom();

  useEffectOnce(() => {
    intercom.update({
      hideDefaultLauncher: true,
      userHash: intercomHash ?? undefined,
      actionColor: primaryColor,
      backgroundColor: primaryColor,
      company: {
        companyId: company.id,
        name: company.displayName,
      },
      email: viewer.email,
      userId: viewer.id,
      name: [viewer.firstName, viewer.lastName].join(' '),
      customAttributes: {
        isCompanyAdmin: company.viewerIsAdmin,
      },
    });
  });

  useEffect(() => {
    applyThemeColor(primaryColor);
    intercom.update({
      actionColor: primaryColor,
      backgroundColor: primaryColor,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryColor]);

  return (
    <AuthenticatedContextProvider company={company} viewer={viewer}>
      <InstantSearch<UiState, RouteState>
        searchClient={searchClient}
        indexName={campaignIndex.txt}
        future={FUTURE}
        insights
      >
        <div className={'max-sm:mobile-container'}>
          <div className={'min-h-[100dvh] relative'}>
            <AppHeader
              className={cx(
                'hidden fixed top-0 left-64 right-0 z-50 h-16',
                'md:flex',
                zeroRightClassName,
              )}
              company={company}
              viewer={viewer}
            />
            <AppMenu
              className="hidden md:flex flex-col fixed left-0 top-0 bottom-0 w-64 z-50 bg-white"
              company={company}
            />
            <main className={cn('md:pl-64 md:pt-16 pb-14 md:pb-0 relative')}>
              <div className="min-h-[calc(100vh-56px)] md:min-h-[calc(100vh-64px)] flex flex-col">
                <Outlet />
              </div>
              <AppFooter company={company} />
            </main>
            <MobileTabBar
              communicationsEnabled={!!company.features.communicationMode}
              subventionsEnabled={!!company.features.subventions}
            />
            <Showstopper
              companyLogo={company.logoUrl}
              companyDisplayName={company.displayName}
            />
          </div>
        </div>
        <AlgoliaSearchInsights
          viewer={viewer}
          appId={env.REACT_APP_ALGOLIA_APP_ID}
          apiKey={securedApiKeyData.userAlgoliaSecuredKeyAPi}
        />
        <CustomerIO viewer={viewer} />
        <Satismeter viewer={viewer} />
        <Hotjar viewer={viewer} />
        <AmplitudeViewer viewer={viewer} />
      </InstantSearch>
    </AuthenticatedContextProvider>
  );
};

export const Route = createFileRoute('/_authenticated-layout')({
  beforeLoad: async ({ context, location, search }) => {
    if (!context.viewer) {
      const isPartnerRoute = location.pathname.startsWith('/partner');
      throw redirect({
        to: '/auth/login',
        search: search.redirect
          ? {
              redirect: isPartnerRoute
                ? `${location.pathname}?redirect=${search.redirect}`
                : search.redirect,
            }
          : undefined,
      });
    }

    if (context.viewer.role !== UserRole.User)
      throw redirect({ to: '/superadmin' });
    if (!context.viewer.company || context.viewer.company?.viewerIsSuspended)
      throw redirect({ to: '/suspended/orders/' });

    const { data } = await context.apolloClient.query<
      AuthenticatedLayoutQuery,
      AuthenticatedLayoutQueryVariables
    >({
      query: AuthenticatedLayoutDocument,
    });

    const viewer = data.viewer;
    const company = viewer?.company;

    return { viewer, company };
  },
  component: AuthenticatedLayout,
  pendingComponent: () => <Spin />,
  validateSearch: z.object({
    redirect: z.string().optional(),
  }),
});
