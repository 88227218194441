import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { env } from '@env';
import { Button, Typography } from '@happypal-tech/design-system';
import { LoginForm } from '@src/features-new/auth/components/LoginForm/LoginForm';
import { PageTitle } from '@src/features-new/auth/components/PageTitle';
import { PublicSsoProvider } from '@src/graphql/generated/types';
import { generateCodeVerifier, storeCodeVerifier } from '@src/utils/auth';
import { Link, useLoaderData } from '@tanstack/react-router';

interface PublicSsoProviderWithChallenge {
  sso: PublicSsoProvider;
  challenge: ReturnType<typeof generateCodeVerifier>;
}

export function getPublicSsoProviderUrl(ssoUrl: string, codeChallenge: string) {
  const url = new URL(ssoUrl);
  url.searchParams.append(
    'returnTo',
    encodeURIComponent(env.REACT_APP_SSO_WEBHOOK_URL),
  );
  url.searchParams.append('codeChallenge', encodeURIComponent(codeChallenge));
  return url.toString();
}

export const SSOPage = () => {
  const { slug, providers } = useLoaderData({
    from: '/auth/sso/$slug',
  });
  const providersWithChallenge: PublicSsoProviderWithChallenge[] = useMemo(
    () => providers.map((p) => ({ sso: p, challenge: generateCodeVerifier() })),
    [providers],
  );
  const { t } = useTranslation('auth', {
    keyPrefix: 'auth.ssoPage',
  });

  const providerDisplayedName = providersWithChallenge[0]?.sso.name || slug;
  const isProviders = providersWithChallenge.length > 0;

  return (
    <div className="mx-auto w-full h-full justify-between flex flex-col md:justify-normal">
      <div>
        <PageTitle title={t('title')} />
        <Typography type="body" className="mt-4 text-neutral-darkest text-left">
          {t('subTitle', {
            company: providerDisplayedName,
          })}
        </Typography>
      </div>
      {!isProviders ? (
        <LoginForm />
      ) : (
        <div className="flex flex-col gap-4 mt-8 justify-end md:justify-normal">
          {providersWithChallenge.map((provider) => (
            <Button
              key={provider.sso.id}
              fluid
              variant="stroke"
              onClick={async () => {
                const challenge = await provider.challenge;
                storeCodeVerifier(challenge.codeVerifier);
                window.location.assign(
                  getPublicSsoProviderUrl(
                    provider.sso.url,
                    challenge.codeChallenge,
                  ),
                );
              }}
            >
              {t('submit', {
                company: provider.sso.name,
              })}
            </Button>
          ))}
          <Link to="/auth/login" className="w-full">
            <Button
              fluid
              color="neutral"
              variant="ghost"
              iconLeft="ArrowLeftOutline"
            >
              {t('backToLogin')}
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};
