import { ResponsiveSkeleton } from '@components/molecules/ResponsiveSkeleton/ResponsiveSkeleton';
import { useNavigationHistory } from '@contexts/NavigationHistoryProvider/NavigationHistoryProvider';
import { Button } from '@happypal-tech/design-system';
import { ProgressivePicture } from '@src/components/atoms/ProgressivePicture';
import { ProgressivePictureFragment } from '@src/components/atoms/ProgressivePicture/ProgressivePicture.generated';

type DetailsCoverProps = {
  title: string;
  subtitle: string;
  cover?: ProgressivePictureFragment | null;
  loading?: boolean;
};

export const DetailsCover = (props: DetailsCoverProps) => {
  const { cover, loading, title, subtitle } = props;
  const { navigateToPrev } = useNavigationHistory();

  return (
    <div className="relative h-64 w-full md:h-80">
      <div className="relative h-full">
        <Button
          color="neutral"
          icon="ChevronLeftOutline"
          size="small"
          onClick={() => navigateToPrev('/catalog')}
          className="absolute top-6 left-6 md:hidden z-30"
        />
        {loading ? (
          <ResponsiveSkeleton
            sm={{
              contentLoader: {
                width: '100%',
                height: 256,
              },
              children: (
                <rect x="0" y="0" rx="0" ry="0" width="100%" height="256" />
              ),
            }}
            md={{
              contentLoader: {
                width: '100%',
                height: 320,
                radius: 12,
              },
              children: (
                <rect x="0" y="0" rx="0" ry="0" width="100%" height="320" />
              ),
            }}
          />
        ) : (
          <div className="absolute inset-0 bg-grey 2xl:rounded-xl" />
        )}
        {!!cover && (
          <>
            <div className="absolute inset-0 2xl:rounded-xl">
              <ProgressivePicture
                picture={cover}
                alt="campaign cover"
                className="absolute object-cover 2xl:rounded-xl"
              />
            </div>
            <div className="absolute inset-0 bg-black-transparent 2xl:rounded-xl" />
          </>
        )}
      </div>
      <div className="absolute left-1/2 top-1/2 z-30 hidden -translate-x-1/2 -translate-y-1/2 transform flex-col items-center md:flex">
        <span className="mb-3 text-xs font-bold uppercase tracking-widest text-white">
          {subtitle}
        </span>
        <span className="text-center font-display text-5xl font-bold text-white">
          {title}
        </span>
      </div>
    </div>
  );
};
