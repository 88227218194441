import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { Icon } from '@src/components/atoms/Icon';
import { cn } from '@src/features-new/ui/cn';
import usePortal from '@utils/hooks/usePortal';
import { KeyboardAwareScrollView } from '@utils/KeyboardAware';
import { cx } from 'class-variance-authority';
import { AnimatePresence, motion } from 'framer-motion';

// The contentVariants for the drawer itself
const contentVariants = {
  open: { opacity: 1, x: 0 },
  close: { opacity: 0, x: '100%' },
};

// The variants for the drawer backdrop overlay
const overlayVariants = {
  open: { opacity: 1 },
  close: { opacity: 0 },
};

type DrawerProps = {
  isOpen: boolean;
  children: React.ReactNode;
  onCloseModal: () => void;
  onBackgroundClick?: () => void;
  hideBackButton?: boolean;
  disableBackgroundClick?: boolean;
  className?: string;
  rootClassName?: string;
};
/**
 * @deprecated
 */
export const Drawer = ({
  onCloseModal,
  isOpen,
  children,
  hideBackButton = false,
  disableBackgroundClick,
  className,
  rootClassName,
}: DrawerProps) => {
  const { t } = useTranslation('common');
  const targetElement = usePortal('root');
  const onCloseModalRef = useRef(onCloseModal);

  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'unset';
  }, [isOpen]);

  useEffect(() => {
    onCloseModalRef.current = onCloseModal;
  }, [onCloseModal]);

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onCloseModalRef.current();
      }
    };

    if (!disableBackgroundClick && isOpen) {
      document.addEventListener('keydown', handleKeydown);
      return () => document.removeEventListener('keydown', handleKeydown);
    }
  }, [disableBackgroundClick, onCloseModalRef, isOpen]);

  if (!targetElement) {
    console.error("portalElement for drawer does't exist");
    return null;
  }

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key="drawer-overlay"
          className={cx(
            rootClassName,
            'fixed left-0 top-0 z-drawer h-screen w-full bg-black bg-opacity-10',
          )}
          initial="close"
          animate="open"
          exit="close"
          variants={overlayVariants}
          transition={{ type: 'tween' }}
          onClick={() => {
            if (disableBackgroundClick) return;
            onCloseModal();
          }}
        >
          <motion.div
            key="drawer-content"
            className="fixed inset-0 md:left-auto z-modal bg-white"
            initial="close"
            animate="open"
            exit="close"
            variants={contentVariants}
            onClick={(e) => e.stopPropagation()}
            transition={{ type: 'spring', stiffness: 350, damping: 40 }}
          >
            {/* Adds a "card-like" look to the drawer */}
            <KeyboardAwareScrollView
              className={cn(
                'h-full md:w-[500px] select-text bg-white shadow-large overflow-auto',
                className,
              )}
            >
              {/* Drawer content */}
              {children}
            </KeyboardAwareScrollView>
            {!hideBackButton && (
              <div className="absolute right-5 top-5 z-toast flex h-10 w-10 cursor-pointer items-center justify-center pt-safe">
                <button
                  className="flex flex-row items-center align-middle"
                  type="button"
                  onClick={onCloseModal}
                >
                  <div className="mr-1 text-tiny font-medium text-neutral-dark">
                    {t('buttons.close', 'Fermer')}
                  </div>
                  <Icon
                    name="chevronRight"
                    className="text-grey"
                    size="small"
                  />
                </button>
              </div>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    targetElement,
  );
};

export default Drawer;
