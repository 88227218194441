import {
  ComponentProps,
  forwardRef,
  KeyboardEvent,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  InputCombobox,
  InputComboboxItem,
  Typography,
} from '@happypal-tech/design-system';
import { CatalogEmptyState } from '@src/features-new/core/CatalogEmptyState/CatalogEmptyState';
import { CatalogSearchBarEvents } from '@src/features-new/core/CatalogSearchBar/events';
import { hasStringQuery } from '@src/features-new/core/CatalogSearchBar/hasStringQuery';
import { Overlay } from '@src/features-new/core/CatalogSearchBar/Overlay';
import { useSuggestionsComboboxQuery } from '@src/features-new/core/CatalogSearchBar/SuggestionsCombobox.generated';
import {
  Suggestion,
  SuggestionsEntry,
} from '@src/features-new/core/CatalogSearchBar/SuggestionsEntry';
import { useSearch } from '@src/features-new/core/CatalogSearchBar/use-search';
import { cn } from '@src/features-new/ui/cn';
import { useNavigate } from '@tanstack/react-router';

interface CatalogSearchBarSuggestionsComboboxProps
  extends Pick<
      ComponentProps<typeof InputCombobox>,
      | 'append'
      | 'onKeyDown'
      | 'onBlur'
      | 'isMenuOpen'
      | 'search'
      | 'placeholder'
    >,
    CatalogSearchBarEvents {
  className?: string;
  onSearch?: (value: string) => void;
  onOpenChange: (nextIsOpen: boolean) => void;
}

export const CatalogSearchBarSuggestionsCombobox = forwardRef<
  HTMLInputElement,
  CatalogSearchBarSuggestionsComboboxProps
>(
  (
    {
      className,
      onSearch,
      append,
      onKeyDown,
      onBlur,
      isMenuOpen,
      search,
      placeholder,
      onOpenChange,
      onExploreCampaign,
      onClickCompleteSearch,
      onClickSeeAll,
    },
    forwardedRef,
  ) => {
    const navigate = useNavigate();

    const { t } = useTranslation('core', {
      keyPrefix: 'components.CatalogSearchBar.suggestions',
    });

    const [favoriteCampaignIds, setFavoriteCampaignIds] = useState<string[]>(
      [],
    );

    const {
      loading,
      hits = [],
      nbHits = 0,
    } = useSearch<Suggestion>({
      query: search,
      skipSearch: !search,
      options: { hitsPerPage: 4 },
    });

    useEffect(() => {
      if (!search) {
        onOpenChange(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const { data } = useSuggestionsComboboxQuery({
      variables: {
        campaignIds: hits.map((el) => el.objectID),
      },
      fetchPolicy: 'cache-and-network',
      skip: hits.length === 0,
    });

    useEffect(() => {
      if (data) {
        // we use a state to prevent child component blink when data is undefined
        setFavoriteCampaignIds(
          data.campaignCardInformations
            .filter((el) => el.isFavorite === true)
            .map((el) => el.campaignId),
        );
      }
    }, [data, setFavoriteCampaignIds]);

    const inputComboboxRef = useRef<HTMLInputElement>(null);

    const items = hits.map<InputComboboxItem>(({ objectID, name, ...rest }) => {
      return {
        value: objectID,
        label: name,
        renderEntry: ({ inputValue, isHighlighted }) => (
          <SuggestionsEntry
            name={name}
            inputValue={inputValue}
            isFavorite={favoriteCampaignIds.includes(objectID)}
            campaignId={objectID}
            isHighlighted={isHighlighted}
            {...rest}
          />
        ),
      };
    });

    const onChange = (value: InputComboboxItem | null) => {
      if (value === null) {
        return;
      }
      onExploreCampaign();
      return navigate({
        to: '/explore/$campaignId',
        params: { campaignId: value.value },
      });
    };

    const onClickSeeResults = () => {
      inputComboboxRef.current?.blur();
      if (nbHits > 0 && hasStringQuery(search)) {
        return onClickCompleteSearch(search);
      }

      onSearch?.('');
      return onClickSeeAll();
    };

    // TODO: Spaghetti code. InputCombobox should be extracted from the design-system and re-engineered
    const handleOpenChange = (nextIsOpen: boolean) => {
      if (nextIsOpen === true && search?.trim() === '') {
        return;
      }
      onOpenChange(nextIsOpen);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onOpenChange(!isMenuOpen);
      }
      return onKeyDown?.(e);
    };

    useImperativeHandle(forwardedRef, () => inputComboboxRef.current!);

    return (
      <>
        <Overlay isOpen={isMenuOpen} />

        <InputCombobox
          ref={inputComboboxRef}
          className={cn('w-96', className)}
          popoverClassName={cn(
            'w-[61rem] p-6 z-50',
            `max-w-[100vw] md:max-w-[calc(100vw-20rem)]`,
          )}
          align="start"
          placeholder={placeholder}
          inputClassName="placeholder-shown:truncate"
          search={search}
          onSearch={onSearch}
          isMenuOpen={isMenuOpen}
          onChange={onChange}
          onOpenChange={handleOpenChange}
          value={null}
          items={items}
          heading={
            <Typography
              type="caption"
              semibold
              asChild
              className="mb-3 text-neutral-dark"
            >
              <p>{t('title')}</p>
            </Typography>
          }
          emptyText={
            loading === false ? <CatalogEmptyState search={search} /> : null
          }
          append={append}
          onKeyDown={handleKeyDown}
          onBlur={onBlur}
        >
          <Button
            type="button"
            variant="plain"
            className="mt-6"
            iconRight={nbHits > 0 ? 'SearchOutline' : 'ArrowRightOutline'}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            onClick={onClickSeeResults}
            fluid
          >
            {nbHits > 0
              ? t('seeAllResults', { count: nbHits })
              : t('seeAllPerks')}
          </Button>
        </InputCombobox>
      </>
    );
  },
);
