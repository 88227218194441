import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApolloError } from '@apollo/client';
import { Tooltip } from '@components/atoms/Tooltip';
import { ApiErrorCode } from '@constants/Errors';
import { Modal, Spin, toast, Typography } from '@happypal-tech/design-system';
import {
  formatDuration,
  intervalToDuration,
} from '@services/dateManager.service';
import { Icon } from '@src/components/atoms/Icon';
import { useAlgoliaSearchInsights } from '@src/lib/algolia/search-insights';
import { cx } from 'class-variance-authority';

import {
  CouponClaimableFragment,
  useCouponClaimableClaimMutation,
} from './CouponClaimable.generated';

type CouponClaimableProps = {
  offer: CouponClaimableFragment;
  updateCouponValue: (couponValue: string | undefined) => void;
  expired: boolean;
  claimedCouponsCount: number;
  campaignId: string;
  queryID?: string;
};

export const CouponClaimable = (props: CouponClaimableProps) => {
  const {
    offer,
    updateCouponValue,
    expired,
    claimedCouponsCount,
    campaignId,
    queryID,
  } = props;
  const { t } = useTranslation();
  const [confirmClaimOpen, setConfirmClaimOpen] = useState(false);
  const { convertedObjectIds } = useAlgoliaSearchInsights();

  const handleCloseModal = () => {
    setConfirmClaimOpen(false);
  };

  let initClaimCouponOutOfStockValue = false;

  if (offer.inventoryQuantity <= 0) {
    initClaimCouponOutOfStockValue = true;
  }

  if (offer.claimCountMax && claimedCouponsCount >= offer.claimCountMax) {
    initClaimCouponOutOfStockValue = true;
  }

  const [claimCouponOutOfStock, setClaimCouponOutOfStock] = useState(
    initClaimCouponOutOfStockValue,
  );

  const [claimCouponMutation, { loading: claimCouponLoading }] =
    useCouponClaimableClaimMutation({
      variables: {
        offerId: offer.id,
      },
    });

  const claimCoupon = async () => {
    try {
      await claimCouponMutation().then(({ data }) => {
        const tmpCouponValue = data?.couponClaimableClaim.claimedCoupon.value;
        updateCouponValue(tmpCouponValue);
        if (tmpCouponValue) {
          navigator.clipboard.writeText(tmpCouponValue);
          toast.success(
            t(
              'campaignDetails.offers.offer.toast.codeGeneratedAndCopied',

              {
                humanTimeInterval: claimIntervalInWords
                  ? claimIntervalInWords
                  : '24h',
              },
            ),
          );
          convertedObjectIds({
            eventName: queryID
              ? 'Coupon Claimed After Search'
              : 'Coupon Claimed',
            objectIDs: [campaignId],
            queryID,
          });
        }
      });
    } catch (err) {
      if (err instanceof ApolloError) {
        err.graphQLErrors.forEach((error) => {
          switch (error.extensions?.code as ApiErrorCode) {
            case 'coupon-claimable/no-claim-left':
            case 'coupon-claimable/out-of-stock':
              setClaimCouponOutOfStock(true);
              break;
            default:
              toast.error(
                t(
                  'campaignDetails.offers.offer.toast.couponClaimableClaimError',
                ),
              );
              break;
          }
        });
      }
    }
  };
  const claimInterval = (offer.claimInterval || 0) * 1000 * 60;

  const claimIntervalInWords = useMemo(() => {
    const claimAgainDate = new Date(new Date().getTime() + claimInterval);

    const duration = intervalToDuration({
      start: new Date(),
      end: claimAgainDate,
    });

    const days =
      (duration.days || 0) +
      (duration.months || 0) * 30 +
      (duration.years || 0) * 365 +
      (duration.hours || 0) / 24;

    if (days <= 3) {
      duration.hours = (duration.hours || 0) + days * 24;

      return formatDuration(duration, 'hours');
    } else {
      return formatDuration(duration, 'days');
    }
  }, [claimInterval]);

  const humanTimeInterval = claimIntervalInWords ? claimIntervalInWords : '24h';

  return (
    <div className="flex flex-shrink-0 flex-row items-center">
      {/* DASHED DIV */}
      <div className="relative flex flex-grow items-center justify-between rounded-xl border-2 border-dashed border-grey-medium py-2 pl-3.5 pr-1.5">
        {claimCouponOutOfStock ? (
          <div className="absolute -top-3 left-0 right-0 flex items-center justify-center text-center">
            <div className=" bg-white px-0.5">
              <p className="text-tiny text-red">
                {t('campaignDetails.offers.offer.couponClaimableOutOfStock')}
              </p>
            </div>
          </div>
        ) : (
          <div className="absolute bottom-8 left-0 right-0 flex items-center justify-center text-center">
            <div className=" bg-white px-0.5">
              <p className="text-tiny">
                {t('campaignDetails.offers.offer.couponClaimableGenerateCode')}{' '}
                <span className="font-bold">
                  {claimIntervalInWords ? claimIntervalInWords : '24h'}
                </span>
              </p>
            </div>
          </div>
        )}
        <div className="flex-shrink flex-grow">
          <p className="font-display text-base font-bold text-black blur-md filter">
            COUPON
          </p>
        </div>
        {claimCouponOutOfStock ? (
          <div className="mr-2 justify-end text-right">
            <p className="text-tiny text-grey">
              {t('campaignDetails.offers.offer.couponComingBackSoon')}
            </p>
          </div>
        ) : (
          <div className="ml-2 flex-shrink-0">
            {claimCouponLoading ? (
              <div className="flex h-6 w-6 flex-row items-center">
                <Spin />
              </div>
            ) : (
              <button
                className={cx('flex items-center justify-center', {
                  'cursor-default': claimCouponOutOfStock || expired,
                })}
                disabled={claimCouponOutOfStock || expired}
                onClick={() => setConfirmClaimOpen(true)}
              >
                <Tooltip
                  id="generateTooltip"
                  label={t('campaignDetails.offers.offer.tooltip.generateCode')}
                  disabled={claimCouponOutOfStock || expired}
                  placement="top"
                >
                  <Icon name="eyeOn" size="medium" className="text-grey" />
                </Tooltip>
              </button>
            )}
          </div>
        )}
      </div>

      <div className="mr-2" />
      <div className="w-[108px] md:w-32">
        {
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <a
            className={cx(
              'flex h-11 w-full items-center justify-center rounded-2xl font-display text-sm font-semibold text-white transition-all duration-300 md:rounded-xl',
              {
                'cursor-default bg-primary-disabled':
                  claimCouponOutOfStock || expired,
                'bg-primary hover:bg-primary-dark':
                  !claimCouponOutOfStock && !expired,
              },
            )}
            onClick={() =>
              convertedObjectIds({
                eventName: 'Offer Link Clicked',
                objectIDs: [campaignId],
                queryID,
              })
            }
            {...(!claimCouponOutOfStock && !expired
              ? {
                  href: offer.url?.href,
                  target: '_blank',
                  rel: 'noreferrer noopener',
                }
              : {})}
          >
            {t('campaignDetails.offers.offer.seeOfferButton')}
          </a>
        }
      </div>
      <Modal.Alert
        opened={confirmClaimOpen}
        rootClassName="z-modal"
        onOpenChange={handleCloseModal}
        title={t(
          'campaignDetails.offers.offer.confirmationModal.couponClaimableClaimTitle',
        )}
        description={t(
          'campaignDetails.offers.offer.confirmationModal.couponClaimableDescription',
          {
            humanTimeInterval,
          },
        )}
        descriptionHidden
        onConfirm={claimCoupon}
        onCancel={handleCloseModal}
        confirmText={t(
          'campaignDetails.offers.offer.confirmationModal.buttons.generate',
        )}
        cancelText={t('common:buttons.cancel')}
      >
        <Typography type="body">
          {t(
            'campaignDetails.offers.offer.confirmationModal.couponClaimableClaimWarning',
            {
              humanTimeInterval,
            },
          )}
        </Typography>
      </Modal.Alert>
    </div>
  );
};
