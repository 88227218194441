import { ReactNode } from 'react';

import { Typography } from '@happypal-tech/design-system';
import { cx } from 'class-variance-authority';

type BadgeProps = {
  value?: number | string;
  children?: ReactNode;
  size?: 'small' | 'medium';
  style?: 'danger' | 'primary' | 'inverse';
  isOutline?: boolean;
  position?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'top-right'
    | 'top-left'
    | 'bottom-right'
    | 'bottom-left'
    | 'cart';
};

export const Badge = (props: BadgeProps) => {
  const {
    value,
    children,
    position = 'top-right',
    size = 'medium',
    style = 'primary',
    isOutline = true,
  } = props;

  return (
    <div className="flex">
      <span className="relative">
        {children}
        {value !== undefined && (
          <span
            className={cx('rounded-full absolute', {
              // Position
              'top-0 left-1/2 -translate-y-1/2 -translate-x-1/2':
                position === 'top' && !!children,
              'top-1/2 left-full -translate-y-1/2 -translate-x-1/2':
                position === 'right' && !!children,
              'top-full left-1/2 -translate-y-1/2 -translate-x-1/2':
                position === 'bottom' && !!children,
              'top-1/2 left-0 -translate-y-1/2 -translate-x-1/2':
                position === 'left' && !!children,
              'top-0 left-full -translate-y-1/3 -translate-x-2/3':
                position === 'top-right' && !!children,
              'top-0 left-0 -translate-y-1/3 -translate-x-1/3':
                position === 'top-left' && !!children,
              'top-full left-full -translate-y-2/3 -translate-x-2/3':
                position === 'bottom-right' && !!children,
              'top-full left-0 -translate-y-2/3 -translate-x-1/3':
                position === 'bottom-left' && !!children,
              // Color
              'bg-error text-white': style === 'danger',
              'bg-primary text-white': style === 'primary',
              'bg-white text-neutral-darkest': style === 'inverse',
              // Size
              'h-2 w-2': size === 'small',
              'flex min-h-5 min-w-5 items-center justify-center px-1':
                size === 'medium',
              // Misc
              'shadow-md': isOutline,
              'top-1 left-full -translate-y-1/3 -translate-x-2/3':
                position === 'cart',
            })}
          >
            {size !== 'small' && (
              <Typography type="legend" semibold>
                {value}
              </Typography>
            )}
          </span>
        )}
      </span>
    </div>
  );
};
