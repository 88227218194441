import React, { createContext, useContext, useEffect, useRef } from 'react';

import { routeTree } from '@src/routes.generated';
import {
  AnyRoute,
  AnySearchSchema,
  RoutePaths,
  useNavigate,
  useRouter,
} from '@tanstack/react-router';

type NavigationHistoryItem = { pathname: string; search: AnySearchSchema };

const NavigationHistoryContext = createContext<{
  history: NavigationHistoryItem[];
  navigateToPrev: (defaultTarget?: RoutePaths<typeof routeTree>) => void;
}>({
  history: [],
  navigateToPrev: (_val) => {
    return;
  },
});

export const useNavigationHistory = () => useContext(NavigationHistoryContext);

export const NavigationHistoryProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter<AnyRoute>();
  const navigate = useNavigate();
  const history = useRef<NavigationHistoryItem[]>([]);

  useEffect(() => {
    const handleNavigation = () => {
      const currentLocation = {
        pathname: router.state.location.pathname,
        search: router.state.location.search,
      };
      if (
        JSON.stringify(history.current[history.current.length - 1]) !==
        JSON.stringify(currentLocation)
      ) {
        history.current.push(currentLocation);
      }
    };

    // Store initial URL
    handleNavigation();

    const unsubscribe = router.subscribe('onResolved', () => {
      handleNavigation();
    });

    return () => {
      unsubscribe();
    };
  }, [router]);

  const value = {
    history: history.current,
    navigateToPrev: (defaultTarget?: RoutePaths<typeof routeTree>) => {
      if (history.current.length > 1) {
        router.history.back();
      } else {
        navigate({ to: defaultTarget || '/home' });
      }
    },
  };

  return (
    <NavigationHistoryContext.Provider value={value}>
      {children}
    </NavigationHistoryContext.Provider>
  );
};
