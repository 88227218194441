import { SSOError } from '@src/routes/auth/~SSOError';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

function SSOErrorCode() {
  const { code } = Route.useSearch();
  const navigate = Route.useNavigate();
  return (
    <SSOError
      error={{ code }}
      reset={() =>
        navigate({ to: '/auth/login', replace: true, resetScroll: true })
      }
    />
  );
}

export const Route = createFileRoute('/auth/sso/error')({
  component: SSOErrorCode,
  validateSearch: z.object({
    code: z
      .enum([
        'identity-provider/invalid-state',
        'identity-provider/state-no-match',
        'identity-provider/invalid-code',
        'identity-provider/not-found',
        'identity-provider/invalid-callback',
        'identity-provider/invalid-user-operation',
        'identity-provider/unknown',
      ])
      .catch('identity-provider/unknown'),
  }),
});
