import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useApolloClient } from '@apollo/client';
import { env } from '@env';
import { Container, Spin } from '@happypal-tech/design-system';
import { PageTitle } from '@src/features-new/auth/components/PageTitle';
import { SSOError } from '@src/routes/auth/~SSOError';
import { getStoredCodeVerifier } from '@src/utils/auth';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import axios, { AxiosError } from 'axios';
import { z } from 'zod';

export const Route = createFileRoute('/auth/sso/callback')({
  component: SSOCallback,
  validateSearch: z.object({ code: z.string() }),
  errorComponent: SSOError,
});

function SSOCallback() {
  const { t } = useTranslation('auth', {
    keyPrefix: 'auth.ssoCallbackPage',
  });
  const [error, setError] = useState<Error | AxiosError | null>(null);
  const { code } = Route.useSearch();
  const exchanged = useRef(false);
  const { resetStore } = useApolloClient();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      if (code && !exchanged.current) {
        exchanged.current = true;
        const codeVerifier = getStoredCodeVerifier();
        if (!codeVerifier) {
          setError(new Error('Unable to retrieve stored code verifier'));
          return;
        }
        try {
          await axios.post<void>(
            `${env.REACT_APP_API_URL}/auth/sso/exchange-code`,
            {
              code,
              codeVerifier,
            },
            { withCredentials: true },
          );
        } catch (err) {
          if (err instanceof AxiosError) {
            setError(err);
          } else {
            setError(
              new Error(`Failed to exchange code: ${JSON.stringify(err)}`),
            );
          }
          return;
        }
        await resetStore();
        navigate({ to: '/home' });
      }
    })();
  }, [code, navigate, resetStore]);
  if (error) {
    throw error;
  }
  return (
    <div className="flex items-center justify-center min-h-screen">
      <Container className="p-8">
        <PageTitle title={t('title')} />
        <div className="flex items-center justify-center p-4 w-full">
          <Spin />
        </div>
      </Container>
    </div>
  );
}
