import React, { Suspense } from 'react';

import { ApolloClient } from '@apollo/client';
import { NavigationHistoryProvider } from '@contexts/NavigationHistoryProvider/NavigationHistoryProvider';
import { Toaster } from '@happypal-tech/design-system';
import { UserModeContextProvider } from '@src/contexts/userMode/userMode.hooks';
import { AppEngine } from '@src/features-new/core/AppEngine';
import { AppError } from '@src/features-new/core/AppError/AppError';
import { SelectedCompany } from '@src/hooks/authenticated-context/authenticated-context';
import { ConnectedUserFragment } from '@src/index.generated';
import { AmplitudeScript } from '@src/lib/amplitude/amplitude-script';
import {
  createRootRouteWithContext,
  Outlet,
  ScrollRestoration,
} from '@tanstack/react-router';

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production' ||
  import.meta.env.REACT_APP_WITHOUT_TANSTACK_DEVTOOLS
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

interface MyRouterContext {
  apolloClient: ApolloClient<object>;
  viewer: ConnectedUserFragment | null;
  company: SelectedCompany | null;
}

const Component = () => {
  const { viewer } = Route.useRouteContext();

  return (
    <>
      <ScrollRestoration />
      <UserModeContextProvider>
        <AppEngine viewer={viewer} />
        <NavigationHistoryProvider>
          <Outlet />
        </NavigationHistoryProvider>
      </UserModeContextProvider>
      <AmplitudeScript />
      <Toaster />
      <Suspense>
        <TanStackRouterDevtools position="bottom-right" />
      </Suspense>
    </>
  );
};

export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: Component,
  errorComponent: AppError,
});
