import { SSOError } from '@src/routes/auth/~SSOError';
import { getProviders } from '@src/utils/getProviders';
import { createFileRoute, notFound } from '@tanstack/react-router';

import {
  GetRouteCompanyPublicDocument,
  GetRouteCompanyPublicQuery,
  GetRouteCompanyPublicQueryVariables,
} from './~route.generated';

export const Route = createFileRoute('/auth/sso/$slug')({
  loader: async ({ context, params }) => {
    const [companyPublicQuery, providersResponse] = await Promise.all([
      context.apolloClient.query<
        GetRouteCompanyPublicQuery,
        GetRouteCompanyPublicQueryVariables
      >({
        query: GetRouteCompanyPublicDocument,
        variables: {
          slug: params.slug,
        },
      }),
      getProviders(params.slug),
    ]);
    const companyPublic = companyPublicQuery.data.companyPublic;
    const providers = providersResponse?.providers || [];
    if (!companyPublic) throw notFound();
    return { slug: params.slug, companyPublic, providers };
  },
  errorComponent: SSOError,
});
