import { Chip, ChipProps, Icon, IconName } from '@happypal-tech/design-system';
import { cx } from 'class-variance-authority';

export type DetailsKeyPointsProps = {
  className?: string;
  title: string;
  offerTypeTags: { label: ChipProps['label']; type: IconName }[];
  subventionTag?: { label: ChipProps['label']; type: IconName } | null;
};

export function DetailsKeyPoints(props: DetailsKeyPointsProps) {
  const { className, offerTypeTags, subventionTag, title } = props;

  return (
    <div className={cx(className, 'flex items-center')}>
      <div className="hidden md:block">
        <Icon name="AlignLeftLightOutline" size={24} className="text-primary" />
      </div>
      <div className="md:mr-5" />
      <div className="flex flex-col">
        <h3 className="hidden text-base font-bold text-black md:block">
          {title}
        </h3>
        <div className="flex mt-2 gap-2">
          {offerTypeTags.map(({ label, type }, index) => {
            return label ? (
              <Chip
                key={`${label}-${index}`}
                label={label}
                color="black"
                iconLeft={type}
                className="overflow-hidden hidden md:inline-flex"
              />
            ) : null;
          })}
          {subventionTag && (
            <Chip
              key={`${subventionTag.label}`}
              label={subventionTag.label}
              color="black"
              iconLeft={subventionTag.type}
              className="overflow-hidden"
            />
          )}
        </div>
      </div>
    </div>
  );
}
