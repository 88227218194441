import { useTranslation } from 'react-i18next';

import { Icon, Typography } from '@happypal-tech/design-system';
import { Link } from '@tanstack/react-router';

type MobileTabBarProps = {
  subventionsEnabled: boolean;
  communicationsEnabled: boolean;
};

const mobileTabBarItemStyles =
  'flex flex-col flex-1 h-full gap-1 justify-center items-center text-grey transition-colors duration-300';
const mobileTabBarItemActiveStyles = 'text-primary font-bold';
const mobileTabBarItemInactiveStyles = 'font-semibold';
export const MobileTabBar = (props: MobileTabBarProps) => {
  const { t } = useTranslation();
  const { subventionsEnabled, communicationsEnabled } = props;

  return (
    <div
      className={
        'md:hidden fixed left-0 right-0 bottom-0 z-50 drop-shadow-[0_-20px_14px_rgba(0,0,0,0.1)] pb-safe bg-white '
      }
    >
      <menu className={'h-14 flex items-center justify-around'}>
        {communicationsEnabled && (
          <Link
            to="/home"
            className={mobileTabBarItemStyles}
            activeProps={{
              className: mobileTabBarItemActiveStyles,
            }}
            inactiveProps={{
              className: mobileTabBarItemInactiveStyles,
            }}
          >
            {({ isActive }) => (
              <>
                <Icon name={isActive ? 'HomeSolid' : 'HomeOutline'} size={24} />
                <Typography type="legend">
                  {t('navigation.menu.items.desktop.home')}
                </Typography>
              </>
            )}
          </Link>
        )}
        <Link
          to="/discover"
          className={mobileTabBarItemStyles}
          activeProps={{
            className: mobileTabBarItemActiveStyles,
          }}
          inactiveProps={{
            className: mobileTabBarItemInactiveStyles,
          }}
        >
          {({ isActive }) => (
            <>
              <Icon
                name={isActive ? 'SpecialSearchSolid' : 'SpecialSearchOutline'}
                size={24}
              />
              <Typography type="legend">
                {t('navigation.menu.items.mobile.discover')}
              </Typography>
            </>
          )}
        </Link>
        {subventionsEnabled && (
          <Link
            to="/advantages"
            className={mobileTabBarItemStyles}
            activeProps={{
              className: mobileTabBarItemActiveStyles,
            }}
            inactiveProps={{
              className: mobileTabBarItemInactiveStyles,
            }}
          >
            {({ isActive }) => (
              <>
                <Icon
                  name={isActive ? 'AdvantageSolid' : 'AdvantageOutline'}
                  size={24}
                />
                <Typography type="legend">
                  {t('navigation.menu.items.desktop.advantages')}
                </Typography>
              </>
            )}
          </Link>
        )}
        {communicationsEnabled && (
          <Link
            to="/feed"
            className={mobileTabBarItemStyles}
            activeProps={{
              className: mobileTabBarItemActiveStyles,
            }}
            inactiveProps={{
              className: mobileTabBarItemInactiveStyles,
            }}
          >
            {({ isActive }) => (
              <>
                <Icon name={isActive ? 'FeedSolid' : 'FeedOutline'} size={24} />
                <Typography type="legend">
                  {t('navigation.menu.items.desktop.feed')}
                </Typography>
              </>
            )}
          </Link>
        )}
        <Link
          to="/hub/"
          className={mobileTabBarItemStyles}
          activeProps={{
            className: mobileTabBarItemActiveStyles,
          }}
          inactiveProps={{
            className: mobileTabBarItemInactiveStyles,
          }}
        >
          {({ isActive }) => (
            <>
              <Icon
                name={
                  isActive
                    ? 'SpecialSimpleUserSolid'
                    : 'SpecialSimpleUserOutline'
                }
                size={24}
              />
              <Typography type="legend">
                {t('navigation.menu.items.mobile.hub')}
              </Typography>
            </>
          )}
        </Link>
      </menu>
    </div>
  );
};
