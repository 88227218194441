import ContentLoader from 'react-content-loader';

import { Typography } from '@happypal-tech/design-system';
import { CampaignCategories } from '@src/components/atoms/CampaignCategories/CampaignCategories';
import { ProgressivePicture } from '@src/components/atoms/ProgressivePicture';

import { CampaignBrandHeadlinesFragment } from './CampaignBrandHeadlines.generated';

type CampaignBrandHeadlinesProps = {
  campaign: CampaignBrandHeadlinesFragment | null;
  loading: boolean;
};

export const CampaignBrandHeadlines = (props: CampaignBrandHeadlinesProps) => {
  const { loading, campaign } = props;

  const logo = campaign?.brand.logo;
  const name = campaign?.brand.name;

  return (
    <>
      <div className="z-30 -mt-14 flex w-full flex-row justify-between md:-mt-24 2xl:-mt-14">
        <div className="ml-4 flex h-20 w-20 items-center justify-center overflow-hidden rounded-full bg-white p-3.5 shadow md:ml-5 md:h-28 md:w-28 lg:ml-6">
          {loading && <SkeletonBrandLogo />}
          {logo && (
            <ProgressivePicture
              picture={logo}
              className="object-contain"
              alt="brand logo"
              preventBlur
            />
          )}
        </div>
        {/* Share and coins feature */}
      </div>
      <div className="flex flex-col flex-wrap items-left p-4 md:hidden">
        {name && [
          <Typography key="campaign-name" type="heading-2" bold>
            {name}
          </Typography>,
          <div key="campaign-separator" className="mr-2" />,
        ]}
        {campaign && (
          <CampaignCategories
            className="text-xs font-medium text-gray-400"
            campaign={campaign}
          />
        )}
      </div>
    </>
  );
};

const SkeletonBrandLogo = () => {
  return (
    <ContentLoader
      speed={1}
      width="100%"
      height="100%"
      backgroundColor="#F8F9FB"
      foregroundColor="#CCCFE1"
    >
      <circle cx="50%" cy="50%" r="26" />
    </ContentLoader>
  );
};
